'use client';
import React, { useEffect } from 'react';
import Button from '../ui/Button';
import { HiArrowLongRight } from 'react-icons/hi2';
import { useInsightsData } from '@/src/contexts/insights/InsightsContext';
import { LARGE_VIEWPORT_MIN } from '@/src/styles/responsive-breakpoints';
import { INSIGHT_SLUG } from '@/src/constants/route-constants';
import {
  HomepageWhatsUpContainer,
  HomepageWhatsUpContent,
  HomepageWhatsUpInnerContainer,
  HomepageWhatsUpItem,
  HomepageWhatsUpItemContent,
  HomepageWhatsUpItemDate,
  HomepageWhatsUpItemImage,
  HomepageWhatsUpItemImageBox,
  HomepageWhatsUpItemImageChip,
  HomepageWhatsUpItemSubTitle,
  HomepageWhatsUpItemTextContent,
  HomepageWhatsUpItemTitle,
  HomepageWhatsUpText,
} from '@/src/styles/homepage-styles';

const WhatsUpItem = (props: any) => {
  const { itemKey, title, subTitle, blogCategory, image, updatedAt, readingTime, slug } = props;
  const [titleStyle, setTitleStyle] = React.useState({});

  useEffect(() => {
    const whatsUpTitleEl = document.querySelector(`.whatsUp-title-${itemKey}`);
    const whatsUpTitleHeight = whatsUpTitleEl?.clientHeight || 0;
    setTitleStyle(
      window.innerWidth > LARGE_VIEWPORT_MIN
        ? {
            WebkitLineClamp: Math.floor(whatsUpTitleHeight / 50) <= 2 ? '4' : '3',
          }
        : {},
    );
  }, [itemKey]);

  return (
    <HomepageWhatsUpItem>
      <HomepageWhatsUpItemImageBox>
        {typeof image !== 'string' && image?.url && (
          <HomepageWhatsUpItemImage
            src={image?.url}
            alt={image?.alt}
            width={image?.width || 0}
            height={image?.height || 0}
          />
        )}
        {typeof blogCategory !== 'string' && (
          <HomepageWhatsUpItemImageChip>{blogCategory?.name}</HomepageWhatsUpItemImageChip>
        )}
      </HomepageWhatsUpItemImageBox>
      <HomepageWhatsUpItemContent>
        <HomepageWhatsUpItemTextContent>
          <HomepageWhatsUpItemTitle className={`whatsUp-title-${itemKey}`}>{title}</HomepageWhatsUpItemTitle>
          <HomepageWhatsUpItemSubTitle style={titleStyle}>{subTitle}</HomepageWhatsUpItemSubTitle>
          <HomepageWhatsUpItemDate>
            {readingTime} Minute Read |{' '}
            {new Date(updatedAt).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </HomepageWhatsUpItemDate>
        </HomepageWhatsUpItemTextContent>
        <Button
          $buttonType={'primary'}
          href={`/${INSIGHT_SLUG}${slug}`}
          size="large"
          style={{ padding: '8px 16px 8px 0px' }}
        >
          Read Article
          <HiArrowLongRight size={24} />
        </Button>
      </HomepageWhatsUpItemContent>
    </HomepageWhatsUpItem>
  );
};

const HomepageWhatsUp = () => {
  const { insightsData, refresh } = useInsightsData();

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomepageWhatsUpContainer>
      <HomepageWhatsUpInnerContainer>
        <HomepageWhatsUpText>Insights on our minds</HomepageWhatsUpText>
        <HomepageWhatsUpContent>
          {insightsData?.featuredInsights?.map((insight, index) => {
            if (index >= 2) return null;

            return (
              <WhatsUpItem
                itemKey={insight?.id}
                key={insight?.id}
                image={insight?.coverImage}
                title={insight?.header?.title}
                subTitle={insight?.header?.subtitle}
                blogCategory={insight?.blogCategory}
                slug={insight?.slug}
                updatedAt={insight?.updatedAt}
                readingTime={insight?.readingTime}
              />
            );
          })}
          <Button
            $buttonType={'primary'}
            href={`/${INSIGHT_SLUG}`}
            size="large"
            style={{ padding: '8px 16px 8px 0px' }}
          >
            More Articles
            <HiArrowLongRight size={24} />
          </Button>
        </HomepageWhatsUpContent>
      </HomepageWhatsUpInnerContainer>
    </HomepageWhatsUpContainer>
  );
};

export default HomepageWhatsUp;
