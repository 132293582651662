import React from 'react';
import ServicesCircleText from '../../assets/svg/services-circle-text.svg';
import VaultLogoWhite from '../../assets/svg/LogoWhite.svg';
import StrategyGraphicLines from '../../assets/png/strategy-graphic-lines.png';
import StrategyGraphicEarth from '../../assets/png/strategy-graphic-earth.png';
import StrategyGraphicPeople from '../../assets/png/strategy-graphic-people.png';
import DesignGraphicDev from '../../assets/png/design-graphic-dev.png';
import DesignGraphicStart from '../../assets/png/design-graphic-start.png';
import DesignGraphicPostNote from '../../assets/png/design-graphic-postnote.png';
import EngineeringGraphicsPhone from '../../assets/png/engineering-graphics-phone.png';
import EngineeringGraphicsEllipse from '../../assets/png/engineering-graphics-ellipse.png';
import EngineeringGraphicsCircle from '../../assets/png/engineering-graphics-circle.png';
import EngineeringGraphicsRect1 from '../../assets/png/engineering-graphics-rect1.png';
import EngineeringGraphicsRect2 from '../../assets/png/engineering-graphics-rect2.png';
import EngineeringGraphicsPolygon from '../../assets/png/engineering-graphics-polygon.png';
import {
  HomepageAnimatedCircle,
  HomepageAnimatedCircleSvg,
  HomepageAnimatedRectangle,
  HomepageAnimatedVaultLogo,
  HomepageServicesContainer,
  HomepageServicesItem,
  HomepageServicesItemSvgCircle,
  HomepageServicesItemSvgContainer,
  HomepageServicesItemSvgDev,
  HomepageServicesItemSvgEarth,
  HomepageServicesItemSvgEllipse,
  HomepageServicesItemSvgLines,
  HomepageServicesItemSvgPeople,
  HomepageServicesItemSvgPhone,
  HomepageServicesItemSvgPolygon1,
  HomepageServicesItemSvgPolygon2,
  HomepageServicesItemSvgPostNote,
  HomepageServicesItemSvgRect1,
  HomepageServicesItemSvgRect2,
  HomepageServicesItemSvgStart,
  HomepageServicesItemTextContainer,
  HomepageServicesItemTextPrimary,
  HomepageServicesItemTextSecondary,
} from '@/src/styles/homepage-styles';
import { LargeImageQuery } from '@/src/styles/responsive-breakpoints';

export const HomepageServicesBackground = () => {
  return (
    <HomepageServicesContainer className={'panel'}>
      <HomepageAnimatedCircle className={'services-circle'}>
        <HomepageAnimatedCircleSvg
          className={'services-circle-text'}
          src={ServicesCircleText}
          alt={'services-circle-text'}
          sizes={`${LargeImageQuery} 245px, 163px`}
        />
        <HomepageAnimatedVaultLogo
          src={VaultLogoWhite}
          alt={'services-vault-logo'}
          sizes={`${LargeImageQuery} 136px, 110px`}
        />
      </HomepageAnimatedCircle>
      <HomepageAnimatedRectangle className={'services-container'} />
    </HomepageServicesContainer>
  );
};

export const HomepageServicesItem1 = () => {
  return (
    <HomepageServicesItem>
      <HomepageServicesItemSvgContainer className={'services-svg-container1'}>
        <HomepageServicesItemSvgLines
          className={'services-lines-svg'}
          src={StrategyGraphicLines}
          alt={'strategy_graphic-lines'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgEarth
          className={'services-earth-svg'}
          src={StrategyGraphicEarth}
          alt={'strategy_graphic-earth'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPeople
          className={'services-people-svg'}
          src={StrategyGraphicPeople}
          alt={'strategy_graphic-people'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
      </HomepageServicesItemSvgContainer>
      <HomepageServicesItemTextContainer className={'services-item-text1'}>
        <HomepageServicesItemTextPrimary>Strategy</HomepageServicesItemTextPrimary>
        <HomepageServicesItemTextSecondary>
          Businesses can truly prosper the digital age when organizational evolution and growth are
          prioritized. We’ll collaborate with you to develop meaningful approaches to digital transformation,
          enhancing your adaptability, resilience, and competitive edge
        </HomepageServicesItemTextSecondary>
      </HomepageServicesItemTextContainer>
    </HomepageServicesItem>
  );
};

export const HomepageServicesItem2 = () => {
  return (
    <HomepageServicesItem>
      <HomepageServicesItemSvgContainer className={'services-svg-container2'}>
        <HomepageServicesItemSvgDev
          className={'services-dev-svg'}
          src={DesignGraphicDev}
          alt={'design-graphic-dev'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgStart
          className={'services-start-svg'}
          src={DesignGraphicStart}
          alt={'design-graphic-start'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPostNote
          className={'services-post-note-svg'}
          src={DesignGraphicPostNote}
          alt={'design-graphic-post-note'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPeople
          className={'services-people-svg-2'}
          src={StrategyGraphicPeople}
          alt={'strategy_graphic-people'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
      </HomepageServicesItemSvgContainer>
      <HomepageServicesItemTextContainer className={'services-item-text2'}>
        <HomepageServicesItemTextPrimary>Design</HomepageServicesItemTextPrimary>
        <HomepageServicesItemTextSecondary>
          Embedding customer experience into products and design has never been more crucial. We align product
          thinking with platform expertise and digital execution to deliver exceptional customer experiences
          powered by integrated technology and design.
        </HomepageServicesItemTextSecondary>
      </HomepageServicesItemTextContainer>
    </HomepageServicesItem>
  );
};

export const HomepageServicesItem3 = () => {
  return (
    <HomepageServicesItem>
      <HomepageServicesItemSvgContainer className={'services-svg-container3'}>
        <HomepageServicesItemSvgCircle
          className={'services-circle-svg'}
          src={EngineeringGraphicsCircle}
          alt={'engineering-graphics-circle'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgEllipse
          className={'services-ellipse-svg'}
          src={EngineeringGraphicsEllipse}
          alt={'engineering-graphics-ellipse'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPhone
          className={'services-phone-svg'}
          src={EngineeringGraphicsPhone}
          alt={'engineering-graphics-phone'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgRect1
          className={'services-rect-svg-1'}
          src={EngineeringGraphicsRect1}
          alt={'engineering-graphics-rect1'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgRect2
          className={'services-rect-svg-2'}
          src={EngineeringGraphicsRect2}
          alt={'engineering-graphics-rect2'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPolygon1
          className={'services-polygon-svg-1'}
          src={EngineeringGraphicsPolygon}
          alt={'engineering-graphics-polygon1'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
        <HomepageServicesItemSvgPolygon2
          className={'services-polygon-svg-2'}
          src={EngineeringGraphicsPolygon}
          alt={'engineering-graphics-polygon2'}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
      </HomepageServicesItemSvgContainer>
      <HomepageServicesItemTextContainer className={'services-item-text3'}>
        <HomepageServicesItemTextPrimary>Engineering</HomepageServicesItemTextPrimary>
        <HomepageServicesItemTextSecondary>
          When change is constant it demands elevated levels of business agility. We modernized your
          operations, platforms, development and delivery practices to improve customer experience and deliver
          greater business value
        </HomepageServicesItemTextSecondary>
      </HomepageServicesItemTextContainer>
    </HomepageServicesItem>
  );
};
