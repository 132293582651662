import React from 'react';
import Banner from '@/src/components/shared/blocks/banner/Banner';
import { Banner as BannerType } from '@/src/payload-types';

const HomepageStartANewProject = () => {
  const bannerData: BannerType = {
    createdAt: '',
    updatedAt: '',
    id: 'homepage-start-anew-project',
    name: 'Starting a new project?',
    illustration: {
      baseBackground: '#101011',
      halfConfig: {},
      illustrationType: 'none',
    },
    overlay: {
      alt: 'Illustration of a paper plane carrying an envelope',
      createdAt: '2024-08-06T22:22:51.431Z',
      filename: 'paper_plane-overlay.png',
      filesize: 297361,
      focalX: 50,
      focalY: 50,
      height: 786,
      id: '66b2a23b7b36547150c5e83c',
      mimeType: 'image/png',
      thumbnailURL: null,
      updatedAt: '2024-08-06T22:22:51.431Z',
      url: 'paper_plane-overlay.png',
    },
    content: {
      altTextColor: 'linear-gradient(91deg, #FDF4C6 -12.81%, #D9C3FF 57.71%, #FFF9DB 112.99%)',
      contentType: 'cta',
      width: 'full',
      richText: {
        root: {
          children: [
            {
              children: [
                {
                  detail: 0,
                  format: 64,
                  style: '',
                  mode: 'normal',
                  text: 'Starting a new project?',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'heading',
              version: 1,
              tag: 'h3',
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: 'Get a no obligation expert opinion from our team members.',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
              textFormat: 0,
              textStyle: '',
            },
            {
              children: [],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
              textFormat: 0,
              textStyle: '',
            },
            {
              format: '',
              type: 'block',
              version: 2,
              fields: {
                id: '66b28f116a1108000119c990',
                cta: {
                  path: '/contact',
                  title: 'Learn more',
                  target: 'sameTab',
                  buttonIcon: 'arrow',
                  type: 'secondary',
                  theme: 'light',
                },
                blockName: '',
                blockType: 'ctaBlock',
              },
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      },
    },
  };

  return (
    <Banner
      id={'homepage-start-a-new-project'}
      blockType={'BannerRelation'}
      blockName={'homepage-start-anew-project'}
      className={'start-a-new-project'}
      banner={bannerData}
      style={{
        marginBottom: 0,
      }}
    />
  );
};

export default HomepageStartANewProject;
