import React from 'react';
import {
  HomepageAboutUsBox1,
  HomepageAboutUsBox2,
  HomepageAboutUsBox3,
  HomepageAboutUsBoxFooter,
  HomepageAboutUsBoxFooterImage,
  HomepageAboutUsBoxFooterTexts,
  HomepageAboutUsBoxName,
  HomepageAboutUsBoxText,
  HomepageAboutUsBoxTitle,
  HomepageAboutUsContainer,
  HomepageAboutUsContent,
  HomepageAboutUsInnerContainer,
  HomepageAboutUsText,
} from '@/src/styles/homepage-styles';
import LogoVCIS from '@/src/assets/svg/clientLogoVCIS.svg';
import LogoLFB from '@/src/assets/svg/clientLogoLFB.svg';
import LogoREL from '@/src/assets/svg/clientLogoREL.svg';

const HomepageAboutUs = () => {
  return (
    <HomepageAboutUsContainer>
      <HomepageAboutUsInnerContainer>
        <HomepageAboutUsText className={'about-us-text'}>What they say about us</HomepageAboutUsText>
        <HomepageAboutUsContent>
          <HomepageAboutUsBox1 className={'about-us-box-1'}>
            <HomepageAboutUsBoxText>
              {
                '"I confidently recommend Vault R&D to any business that is looking for an accelerated IT solution deployment. In under 4-months, they delivered a feature-rich MVP. As we look ahead, we\'re certain that Vault will remain our go-to partner for further innovation."'
              }
            </HomepageAboutUsBoxText>
            <HomepageAboutUsBoxFooter>
              <HomepageAboutUsBoxFooterImage src={LogoVCIS} alt={'Vital Care Infusion Services'} />
              <HomepageAboutUsBoxFooterTexts>
                <HomepageAboutUsBoxName>Ricardo Freyre</HomepageAboutUsBoxName>
                <HomepageAboutUsBoxTitle>Director PMO, Vital Care</HomepageAboutUsBoxTitle>
              </HomepageAboutUsBoxFooterTexts>
            </HomepageAboutUsBoxFooter>
          </HomepageAboutUsBox1>
          <HomepageAboutUsBox2 className={'about-us-box-2'}>
            <HomepageAboutUsBoxText>
              {
                '"Shaheen and his team were instrumental in designing our envisioned product. He is extremely knowledgeable in this space, and his expertise saved us from potential future headaches. I recommend Vault R&D to anyone needing professional services in this field."'
              }
            </HomepageAboutUsBoxText>
            <HomepageAboutUsBoxFooter>
              <HomepageAboutUsBoxFooterImage src={LogoLFB} alt={'LocalFoodBuzz'} />
              <HomepageAboutUsBoxFooterTexts>
                <HomepageAboutUsBoxName>Chris Peterson</HomepageAboutUsBoxName>
                <HomepageAboutUsBoxTitle>CEO of LocalFoodBuzz</HomepageAboutUsBoxTitle>
              </HomepageAboutUsBoxFooterTexts>
            </HomepageAboutUsBoxFooter>
          </HomepageAboutUsBox2>
          <HomepageAboutUsBox3 className={'about-us-box-3'}>
            <HomepageAboutUsBoxText>
              {
                '"Vault have overseen 100% of our development work, from start to finish. They’re a well organized team and communicate well, which is one of the most important things we look for in a development firm. I highly recommend them!"'
              }
            </HomepageAboutUsBoxText>
            <HomepageAboutUsBoxFooter>
              <HomepageAboutUsBoxFooterImage src={LogoREL} alt={'Real Estate Lab'} />
              <HomepageAboutUsBoxFooterTexts>
                <HomepageAboutUsBoxName>David Toupin</HomepageAboutUsBoxName>
                <HomepageAboutUsBoxTitle>CEO of Real Estate Lab</HomepageAboutUsBoxTitle>
              </HomepageAboutUsBoxFooterTexts>
            </HomepageAboutUsBoxFooter>
          </HomepageAboutUsBox3>
        </HomepageAboutUsContent>
      </HomepageAboutUsInnerContainer>
    </HomepageAboutUsContainer>
  );
};

export default HomepageAboutUs;
