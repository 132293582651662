'use client';

import styled from 'styled-components';
import Image from 'next/image';
import { LargeMediaQuery, MediumMediaQuery, XLargeMediaQuery } from '@/src/styles/responsive-breakpoints';
import {
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  BodyRegular,
  BodyLarge,
} from './shared-styles';

export const HomepageContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomepageSection = styled.section`
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: hidden;
  transition: 1s;
  transform: translateY(100%);
  padding: 0 16px;
  min-height: var(--homepage-min-height);
  min-width: var(--homepage-min-width);
  transform-origin: top;
`;

export const HomepageSectionOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MediumMediaQuery} {
    max-width: var(--container-width-large);
  }

  ${LargeMediaQuery} {
    max-width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    max-width: var(--container-width-xl);
  }
`;

export const HomepageSectionInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Homepage initial comps

export const HomepageInitialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--spacing-section-medium);
  padding-top: var(--spacing-header-small);
  height: calc(100vh - 100px);

  ${MediumMediaQuery} {
    height: 100vh;
  }
`;

export const HomepageInitialSecondContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 0;
  background: linear-gradient(265deg, #fff -16.79%, #7a98ff 118.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: calc(100vh - 100px);
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  line-height: var(--line-height-h4);

  ${MediumMediaQuery} {
    padding: 0 24px;
    height: 100vh;
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: var(--line-height-h3);
  }
`;

export const HomepagePrimaryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  min-width: 100%;
  height: 100px;

  ${MediumMediaQuery} {
    width: 100%;
    min-width: 100%;
    height: 150px;
  }

  ${LargeMediaQuery} {
    width: 100%;
    min-width: 100%;
    height: 260px;
  }

  ${XLargeMediaQuery} {
    width: 100%;
    min-width: 100%;
    height: 260px;
  }
`;

export const AboutUsInitialTitle1 = styled.h1`
  font-style: normal;
  color: #fff;
  text-wrap: nowrap;
  font-size: 50px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.5px;
  width: fit-content;
  transition: 1s;
  transform: translateX(-75%) translateY(-20%) scale(1.5);
  background: linear-gradient(275deg, #D4BCFF 21.98%, #FAFFFF 100.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${MediumMediaQuery} {
    font-size: 75px;
    line-height: 100px;
  }

  ${LargeMediaQuery} {
    opacity: 0;
    transition: 1s;
    font-size: 130px;
    line-height: 150px;
  }
`;

export const AboutUsInitialTitle2 = styled(AboutUsInitialTitle1)`
  transform: translateX(38%) translateY(-20%) scale(1.5);
`;

export const HomepageSecondaryText = styled(BodyLarge)`
  text-align: center;
  color: white;
  transition: 1s;
  transform: translateY(100px);
`;

export const HomepageLearnMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: 125px;
`;

export const HomepageLearnMoreText = styled(Headline5)`
  color: white;
`;

export const HomepageArrowUpIcon = styled(Image)`
  transition: 1s;
  transform: translateY(50px);
  width: 41px;
  height: auto;
`;

// Homepage services comps

export const HomepageServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 150vh;
`;

export const HomepageAnimatedCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 210px;
  min-height: 210px;
  max-height: 210px;
  width: 210px;
  transition: 1s;
  position: fixed;
  top: 0;
  max-width: 100vw;
  background-image: linear-gradient(152deg, #721be4 100%, #4563de 119.42%);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 1;

  ${LargeMediaQuery} {
    height: 300px;
    min-height: 300px;
    max-height: 300px;
    width: 300px;
  }
`;

export const HomepageAnimatedCircleSvg = styled(Image)`
  transition: 1s;
  width: 163px;
  height: auto;

  ${LargeMediaQuery} {
    width: 245px;
  }
`;
export const HomepageAnimatedVaultLogo = styled(Image)`
  position: absolute;
  width: 110px;
  height: auto;

  ${LargeMediaQuery} {
    width: 136px;
  }
`;

export const HomepageAnimatedRectangle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  position: fixed;
  top: 208px;
  width: 210px;
  max-width: 100vw;
  background-image: linear-gradient(152deg, #721be4 46.47%, #4563de 119.42%);
  height: calc(150vh - 210px);

  ${LargeMediaQuery} {
    top: 298px;
    width: 300px;
    height: calc(150vh - 300px);
  }
`;

export const HomepageServicesItem = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 24px;
  pointer-events: none;
  width: 100%;
  height: calc(100vh - 100px);
  min-height: calc(var(--homepage-min-height) - 100px);
  min-width: var(--homepage-min-width);

  ${MediumMediaQuery} {
    height: 100vh;
    width: 100%;
    gap: 24px;
    flex-direction: column-reverse;
    padding: 0;
  }

  ${LargeMediaQuery} {
    flex-direction: row;
    gap: 10%;
    width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    width: var(--container-width-xl);
  }
`;

export const HomepageServicesItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0;
  text-align: center;
  transition: 1s;
  transform: translateY(0);
  opacity: 0;

  ${MediumMediaQuery} {
    padding: var(--spacing-48);
    transform: translateY(100%);
  }

  ${LargeMediaQuery} {
    padding: 0;
    align-items: flex-start;
    text-align: start;
  }
`;

export const HomepageServicesItemTextPrimary = styled(Headline3)`
  width: 100%;
  background: linear-gradient(274deg, #d4bcff 6.31%, #faffff 101.32%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${MediumMediaQuery} {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    line-height: var(--line-height-h2);
  }
`;

export const HomepageServicesItemTextSecondary = styled(BodyRegular)`
  color: white;

  ${MediumMediaQuery} {
    font-size: var(--font-size-body-large);
    font-weight: var(--font-weight-body-large);
    line-height: var(--line-height-body-large);
  }
`;

export const HomepageServicesItemSvgContainer = styled.div`
  min-width: 400px;
  width: 400px;
  height: 320px;
  position: relative;
  transform-origin: center;
  transform: translateX(-20px) scale(0.7);

  ${MediumMediaQuery} {
    min-width: 456px;
    width: 456px;
    height: 380px;
    transform: scale(0.8);
  }

  ${LargeMediaQuery} {
    transform: scale(1);
  }
`;

export const HomepageServicesItemSvgLines = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: 1s;
  transform: translateY(0);
`;

export const HomepageServicesItemSvgEarth = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 2;
  left: 5%;
  top: 15%;
  transition: 1s;
  transform: translate(-35%, 5%) rotate(-15deg);
`;

export const HomepageServicesItemSvgPeople = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 3;
  left: 40%;
  top: 40%;
  transition: 1s;
  transform: translate(30%, 5%) rotate(15deg);
`;

export const HomepageServicesItemSvgDev = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 10%;
  top: 15%;
  transition: 1s;
`;

export const HomepageServicesItemSvgStart = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: -20%;
  top: 45%;
  transition: 1s;
`;

export const HomepageServicesItemSvgPostNote = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 100%;
  top: 20%;
  transition: 1s;
  transform: rotate(30deg);
`;

export const HomepageServicesItemSvgPhone = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 27%;
  top: 33%;
  transition: 1s;
`;

export const HomepageServicesItemSvgEllipse = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 35%;
  top: 40%;
  transition: 1s;
`;

export const HomepageServicesItemSvgCircle = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 5%;
  transition: 1s;
  transform: rotate(-150deg);
`;

export const HomepageServicesItemSvgRect1 = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 46%;
  top: 40%;
  transition: 1s;
`;

export const HomepageServicesItemSvgRect2 = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 50%;
  top: 35%;
  transition: 1s;
`;

export const HomepageServicesItemSvgPolygon1 = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 65%;
  top: 70%;
  transition: 1s;
`;

export const HomepageServicesItemSvgPolygon2 = styled(Image)`
  position: absolute;
  opacity: 0;
  z-index: 1;
  left: 60%;
  top: 75%;
  transition: 1s;
`;

// Homepage capabilities comps

export const HomepageCapabilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  min-height: var(--homepage-min-height);
  min-width: var(--homepage-min-width);
  gap: var(--spacing-section-medium);
  padding-top: var(--spacing-section-large);
`;

export const HomepageCapabilitiesSecondaryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  ${LargeMediaQuery} {
    align-items: center;
  }
`;

export const HomepageCapabilitiesSecondaryContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-section-small);
  padding: 50px var(--side-margin-small) var(--side-margin-small) var(--side-margin-small);
  margin-bottom: 50px;

  ${MediumMediaQuery} {
    gap: var(--spacing-section-small);
    margin-top: var(--side-margin-small);
    margin-bottom: 0;
  }

  ${LargeMediaQuery} {
    gap: var(--spacing-section-medium);
    flex-direction: row;
  }
`;

export const HomepageCapabilitiesHeader = styled(Headline4)`
  text-align: center;
  color: white;
  transform: translateY(0);
  transition: 1s;

  ${MediumMediaQuery} {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: var(--line-height-h3);
  }
  ${LargeMediaQuery} {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    line-height: var(--line-height-h2);
  }
`;

export const HomepageCapabilitiesHeaderInner = styled.span`
  background: linear-gradient(265deg, #cfaaff -16.79%, #6386ff 51.01%, #b18cff 118.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HomepageCapabilitiesSecondaryHeader = styled(Headline4)`
  width: 100%;
  color: white;
  text-align: center;
  align-self: flex-start;
  font-weight: 700;
  line-height: 150%;

  ${MediumMediaQuery} {
    width: 330px;
    text-align: start;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    line-height: var(--line-height-h2);
  }
`;

export const HomepageCapabilitiesSvgContainer = styled.div`
  width: 340px;
  height: 400px;
  position: relative;
  padding-top: var(--spacing-section-medium);
  transform-origin: top;
  margin-top: -40px;
  transform: scale(0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  ${MediumMediaQuery} {
    margin-top: 0;
    transform: scale(1);
  }
`;

export const HomepageCapabilitiesCircleSvg = styled(Image)`
  text-align: center;
  color: white;
  position: absolute;
  transform: translateY(0);
  transition: 1s;
  bottom: 80px;
`;

export const HomepageCapabilitiesJumpSvg = styled(Image)`
  text-align: center;
  color: white;
  position: absolute;
  transform: translateY(0);
  transition: 1s;
  left: -20px;
`;

export const HomepageCapabilitiesItems = styled.div`
  min-width: auto;
  ${LargeMediaQuery} {
    min-width: 600px;
  }
`;

export const HomepageCapabilitiesItemText = styled(Headline6)`
  padding: var(--spacing-24) var(--spacing-16);
  text-align: left;
  color: white;

  ${MediumMediaQuery} {
    text-align: center;
    padding: var(--spacing-24) var(--spacing-48);
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
    line-height: var(--line-height-h5);
  }
`;

export const HomepageCapabilitiesItemDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #f2e5ff;
`;

// Homepage success story comps

export const HomepageSuccessStoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 2;
  gap: var(--spacing-section-medium);
  position: relative;
  top: 0;
  left: 0;
`;

export const HomepageSuccessStoryInnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  transition: 0.75s;
  z-index: 1;
  gap: 24px;
  min-height: var(--homepage-min-height);
  min-width: var(--homepage-min-width);
  transform-origin: top;

  ${MediumMediaQuery} {
    gap: 32px;
  }

  ${LargeMediaQuery} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    width: var(--container-width-large);
    padding: 0;
  }

  ${XLargeMediaQuery} {
    width: var(--container-width-xl);
  }
`;

export const HomepageSuccessStoryImageContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
`;

export const HomepageSuccessStoryImageShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000033;
`;

export const HomepageSuccessStoryImage = styled(Image)`
  width: 100vw;
  height: 100vh;
  min-height: var(--homepage-min-height);
  object-fit: cover;
  position: absolute;
  transition: 0.75s;
  clip: rect(0, 100vw, 100vh, 0);

  ${MediumMediaQuery} {
    object-position: 45%;
  }

  ${LargeMediaQuery} {
    object-position: 30%;
  }

  ${XLargeMediaQuery} {
    object-position: center;
  }
`;

export const HomepageSuccessStoryInnerLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
  gap: 12px;

  ${LargeMediaQuery} {
    align-items: start;
    width: 457px;
    min-width: 457px;
  }

  ${XLargeMediaQuery} {
    width: 434px;
    min-width: 434px;
  }
`;

export const HomepageSuccessStoryInnerRight = styled.div`
  display: flex;
  justify-content: center;
`;

export const HomepageSuccessStoryPhones = styled(Image)`
  height: 100%;
  width: 277px;
  max-width: 277px;
  transition: 0.75s;

  ${LargeMediaQuery} {
    width: 424px;
    max-width: 424px;
    margin-right: 104px;
  }
`;

export const HomepageSuccessStoryChip = styled.div`
  text-wrap: nowrap;
  white-space: nowrap;
  max-width: min-content;
  color: white;
  border-radius: 999px;
  background: #9747ff;
  padding: 8px 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.5px;

  ${LargeMediaQuery} {
    padding: 12px 24px;
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
    font-weight: var(--font-weight-h5);
  }
`;

export const HomepageSuccessStoryText = styled(Headline5)`
  color: white;
  text-align: center;
  padding: 0 32px;
  max-width: 457px;
  margin-bottom: -4px;

  ${MediumMediaQuery} {
    margin-bottom: 4px;
  }

  ${LargeMediaQuery} {
    text-align: start;
    padding: 0;
    max-width: unset;
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    line-height: var(--line-height-h3);
  }
`;

// Homepage about us comps

export const HomepageAboutUsContainer = styled.div`
  width: 100%;
  height: 100%;
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--homepage-min-height);
`;

export const HomepageAboutUsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  min-height: var(--homepage-min-height);
  gap: 102px;
  transition: ease-out 0.1s;
  backface-visibility: hidden;
  ${MediumMediaQuery} {
    max-width: var(--container-width-medium);
  }

  ${LargeMediaQuery} {
    gap: 80px;
    max-width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    max-width: var(--container-width-xl);
  }
`;

export const HomepageAboutUsText = styled(Headline4)`
  color: white;
  width: fit-content;
  transition: 1s;
  transform: scale(2);

  ${MediumMediaQuery} {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
  }
`;

export const HomepageAboutUsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 325px;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  border-radius: 16px;

  ${MediumMediaQuery} {
    height: 50vh;
  }
`;

export const HomepageAboutUsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 344px;
  height: auto;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  position: absolute;
  opacity: 0;
  transition: 0.5s;
  transform-origin: top;
  transform: translateY(100%);

  ${MediumMediaQuery} {
    width: 639px;
    height: 484px;
    padding: 24px 57px;
  }

  ${LargeMediaQuery} {
    width: 844px;
    height: 504px;
    padding: 77px 57px;
  }
`;

export const HomepageAboutUsBox1 = styled(HomepageAboutUsBox)`
  background: linear-gradient(248deg, #8131e9 1.79%, #506de1 98.42%);
`;

export const HomepageAboutUsBox2 = styled(HomepageAboutUsBox)`
  background: linear-gradient(157deg, #8131e9 -22.88%, #506de1 128.53%);
`;

export const HomepageAboutUsBox3 = styled(HomepageAboutUsBox)`
  background: linear-gradient(259deg, #8131e9 -26.67%, #506de1 135.82%);
`;

export const HomepageAboutUsBoxText = styled(BodyRegular)`
  width: 100%;
  text-align: center;
  color: white;
  font-family: Bespoke Serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.5px;

  ${MediumMediaQuery} {
    font-size: 27px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.6px;
  }
`;

export const HomepageAboutUsBoxFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const HomepageAboutUsBoxFooterImage = styled(Image)`
  width: 54px;
  height: 54px;

  ${MediumMediaQuery} {
    width: 69px;
    height: 69px;
  }
`;

export const HomepageAboutUsBoxFooterTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const HomepageAboutUsBoxName = styled.span`
  color: white;
  font-size: 19px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;

  ${MediumMediaQuery} {
    font-size: 26px;
  }
`;

export const HomepageAboutUsBoxTitle = styled.span`
  color: white;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.6px;

  ${MediumMediaQuery} {
    font-size: 21px;
    line-height: 33px;
  }
`;

// Homepage what's up comps

export const HomepageWhatsUpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const HomepageWhatsUpInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: var(--spacing-32);
  margin-top: 120px;

  ${MediumMediaQuery} {
    max-width: var(--container-width-medium);
  }

  ${LargeMediaQuery} {
    gap: var(--spacing-48);
    max-width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    max-width: var(--container-width-large);
  }
`;

export const HomepageWhatsUpText = styled(Headline2)`
  color: white;
  text-align: center;
`;

export const HomepageWhatsUpContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--side-margin-medium);

  ${LargeMediaQuery} {
    align-items: flex-start;
  }
`;

export const HomepageWhatsUpItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24);

  ${LargeMediaQuery} {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--spacing-64);
  }
`;

export const HomepageWhatsUpItemImageBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  ${LargeMediaQuery} {
    width: auto;
  }
`;

export const HomepageWhatsUpItemImage = styled(Image)`
  object-fit: cover;
  border-radius: 16px;
  height: 180px;
  width: 100%;

  ${MediumMediaQuery} {
    height: 300px;
  }

  ${LargeMediaQuery} {
    width: 352px;
    height: 287px;
  }
`;

export const HomepageWhatsUpItemImageChip = styled(BodyRegular)`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  background: var(--purple-primary);
  display: none;
  font-size: 16px;
  line-height: 19px;

  ${MediumMediaQuery} {
    display: block;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const HomepageWhatsUpItemContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 32px;
  gap: 24px;

  ${LargeMediaQuery} {
    width: auto;
    margin: 0;
  }
`;

export const HomepageWhatsUpItemTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  gap: 12px;

  ${LargeMediaQuery} {
    height: 264px;
  }
`;

export const HomepageWhatsUpItemDate = styled(BodyRegular)`
  color: white;

  ${MediumMediaQuery} {
    display: none;
  }
`;

export const HomepageWhatsUpItemTitle = styled(Headline5)`
  color: var(--purple-primary-light);
  min-height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  ${MediumMediaQuery} {
    min-height: 72px;
    -webkit-line-clamp: 2;
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
    font-weight: var(--font-weight-h4);
  }

  ${LargeMediaQuery} {
    min-height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`;

export const HomepageWhatsUpItemSubTitle = styled(BodyLarge)`
  color: white;
  display: none;
  ${MediumMediaQuery} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`;
