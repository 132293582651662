'use client';

import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { Observer } from 'gsap/Observer';
import { useGSAP } from '@gsap/react';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import HomepageInitial from '@/src/components/homepage/HomepageInitial';
import HomepageSuccessStory from '@/src/components/homepage/HomepageSuccessStory';
import HomepageAboutUs from '@/src/components/homepage/HomepageAboutUs';
import HomepageWhatsUp from '@/src/components/homepage/HomepageWhatsUp';
import {
  HomepageContainer,
  HomepageInitialSecondContainer,
  HomepageSection,
  HomepageSectionInner,
  HomepageSectionOuter,
} from '@/src/styles/homepage-styles';
import {
  HomepageServicesBackground,
  HomepageServicesItem1,
  HomepageServicesItem2,
  HomepageServicesItem3,
} from '@/src/components/homepage/HomepageServices';
import {
  HomepageCapabilitiesFirst,
  HomepageCapabilitiesSecond,
} from '@/src/components/homepage/HomepageCapabilities';
import { homepageAnimations } from '@/src/components/animations/homepageAnimations';
import { MEDIUM_VIEWPORT_MIN } from '@/src/styles/responsive-breakpoints';
import Footer from '../footer/Footer';
import { GlobalFooter } from '@/src/payload-types';
import HomepageStartANewProject from '@/src/components/homepage/HomepageStartANewProject';

gsap.registerPlugin(Observer);
gsap.registerPlugin(ScrollToPlugin);

const ANIMATION_TIMEOUT = 1000;
const SCROLLING_UP_DIVISOR = 3;

const Homepage = (props: { footerData: GlobalFooter }) => {
  const container = useRef<HTMLDivElement | null>(null);
  const animating = useRef(false);
  const currentIndex = useRef(0);
  const [isTouchDevice, setTouchDevice] = useState(false);
  useEffect(() => {
    const scrollContainer = document.getElementById('scrollContainer');
    if (scrollContainer) {
      scrollContainer.classList.add('fullHeight');
    }
    setTouchDevice(
      'ontouchstart' in window ||
        navigator?.maxTouchPoints > 0 ||
        window.matchMedia('(pointer: coarse)').matches,
    );
    gsap.set(document.querySelector('#scrollContainer'), { overflowY: 'hidden' });
    document.querySelector(':root')?.scrollTo({ top: 0, behavior: 'instant' });
    return () => {
      if (scrollContainer) {
        scrollContainer.classList.remove('fullHeight');
      }
      gsap.set(document.querySelector('#scrollContainer'), { overflowY: 'auto' });
      gsap.set(document.querySelector('header'), { background: 'transparent' });
    };
  }, []);

  useGSAP(
    () => {
      const sections = document.querySelectorAll('section');
      const wrap = gsap.utils.wrap(0, 16);
      const tl = gsap.timeline({ defaults: { duration: 0 } });
      const scaleValY = window.innerHeight / 700 > 1 ? 1 : window.innerHeight / 700;
      const scaleValX = window.innerWidth / 393 > 1 ? 1 : window.innerWidth / 393;
      const scaleVal = scaleValX > scaleValY ? scaleValY : scaleValX;
      const isTouchDevice =
        'ontouchstart' in window ||
        navigator?.maxTouchPoints > 0 ||
        window.matchMedia('(pointer: coarse)').matches;

      const goToInitialSection = () => {
        animating.current = true;
        let sections = document.querySelectorAll('section');
        sections.forEach((section, index) => {
          if (index === 0) {
            tl.to(section, { scale: scaleVal });
            homepageAnimations.section1().onEnter();
            gsap.set('.success-story-container', { scale: scaleVal });
          } else if (index === 2 || index === 8 || index === 11 || index === 12) {
            gsap.to(section, { opacity: 1, autoAlpha: 1, duration: 1 });
          } else if (index === 10) {
            gsap.to(section, { opacity: 0, autoAlpha: 1, duration: 1, scale: scaleVal });
          } else {
            gsap.to(section, { opacity: 1, autoAlpha: 1, duration: 1, scale: scaleVal });
          }
        });
        setTimeout(() => {
          animating.current = false;
        }, ANIMATION_TIMEOUT);
      };

      const gotoSection = (index: number) => {
        if (index >= 16 || index < 0) return null;
        animating.current = true;
        const nextIndex = wrap(index);
        const scrollingDown = nextIndex > currentIndex.current;
        const mobileAndTablet = window.innerWidth <= 1069;
        const secondaryContainerEl = document.querySelector('.capabilities-secondary-container');
        const secondaryContainerHeight = (secondaryContainerEl?.clientHeight || 0) + 100;

        const headerElem = document.querySelector('header');
        if (headerElem) {
          if (nextIndex >= 14) {
            headerElem.style.background = 'var(--background-dark)';
          } else {
            headerElem.style.background = 'transparent';
          }
        }

        if (nextIndex === 0) {
          homepageAnimations.section1().onEnter();
          homepageAnimations.section2().onLeaveToBottom();
        } else if (nextIndex === 1) {
          if (currentIndex.current === 0) {
            homepageAnimations.section1().onLeaveToTop();
            homepageAnimations.section2().onEnter();
          } else if (currentIndex.current === 2) {
            tl.to(sections, {
              duration: 0.75,
              onComplete: () => {
                homepageAnimations.section3().onLeaveToBottom();
                if (currentIndex.current === 1) {
                  // this check is necessary for long scrolls
                  homepageAnimations.section2().onEnter();
                }
              },
            });
            homepageAnimations.section3().collapseContainer();
            homepageAnimations.section4().onLeaveToBottom();
          }
        } else if (nextIndex === 2) {
          if (currentIndex.current === 1) {
            homepageAnimations.section2().onLeaveToTop();
            homepageAnimations.section3().moveToTop();
            tl.to(sections, {
              duration: 0.75,
              onComplete: () => {
                if (currentIndex.current === 2) {
                  homepageAnimations.section3().expandContainer();
                  homepageAnimations.section4().onEnter();
                  homepageAnimations.section5().onLeaveToBottom();
                }
              },
            });
          } else if (currentIndex.current === 3) {
            homepageAnimations.section4().onEnter();
            homepageAnimations.section5().onLeaveToBottom();
          }
        } else if (nextIndex === 3) {
          if (currentIndex.current === 2) {
            homepageAnimations.section4().onLeaveToTop();
            homepageAnimations.section5().onEnterFromTop();
          } else if (currentIndex.current === 4) {
            homepageAnimations.section5().onEnterFromBottom();
            homepageAnimations.section6().onLeaveToBottom();
          }
        } else if (nextIndex === 4) {
          if (currentIndex.current === 3) {
            homepageAnimations.section5().onLeaveToTop();
            homepageAnimations.section6().onEnter();
          } else if (currentIndex.current === 5) {
            homepageAnimations.section3().moveToTop();
            homepageAnimations.section6().onEnter();
            homepageAnimations.section7().onLeaveToBottom();
          }
        } else if (nextIndex === 5) {
          if (currentIndex.current === 4) {
            homepageAnimations.section3().onLeaveToTop();
            homepageAnimations.section6().onLeaveToTop();
            homepageAnimations.section7().onEnter();
          } else if (currentIndex.current === 6) {
            homepageAnimations.section7().onEnter();
            homepageAnimations.section8().onLeaveToBottom();
          }
        } else if (nextIndex === 6) {
          if (currentIndex.current === 5) {
            homepageAnimations.section7().onLeaveToTop();
            homepageAnimations.section8().onEnter();
          } else if (currentIndex.current === 7) {
            if (window.innerHeight <= secondaryContainerHeight) {
              homepageAnimations.section8().onEnter();
            } else {
              animating.current = false;
            }
          }
        } else if (nextIndex === 7) {
          if (currentIndex.current === 6) {
            if (window.innerHeight <= secondaryContainerHeight) {
              homepageAnimations.section8().scrollMobile();
            } else {
              animating.current = false;
            }
          } else if (currentIndex.current === 8) {
            homepageAnimations.section9().onLeaveToBottom();
            if (window.innerHeight <= secondaryContainerHeight) {
              homepageAnimations.section8().scrollMobile();
            } else {
              homepageAnimations.section8().onEnter();
            }
          }
        } else if (nextIndex === 8) {
          if (currentIndex.current === 7) {
            homepageAnimations.section8().onLeaveToTop();
            homepageAnimations.section9().onEnter();
          } else if (currentIndex.current === 9) {
            homepageAnimations.section9().onEnter();
            homepageAnimations.section10().onLeaveToBottom();
            tl.to(sections, {
              duration: 0.5,
              onComplete: () => {
                homepageAnimations.section9().onExpand();
              },
            });
          }
        } else if (nextIndex === 9) {
          if (currentIndex.current === 8) {
            homepageAnimations.section9().onCollapse();
            if (mobileAndTablet) {
              homepageAnimations.section9().onLeaveToTop();
              homepageAnimations.section10().onEnter();
              homepageAnimations.section10().onLeaveBox2();
            } else {
              tl.to(sections, {
                duration: 0.75,
                onComplete: () => {
                  homepageAnimations.section9().onLeaveToTop();
                  homepageAnimations.section10().onEnter();
                  homepageAnimations.section10().onLeaveBox2();
                },
              });
            }
          } else if (currentIndex.current === 10) {
            homepageAnimations.section9().onLeaveToTop();
            homepageAnimations.section10().onEnter();
            homepageAnimations.section10().onLeaveBox2();
          }
        } else if (nextIndex === 10) {
          homepageAnimations.section10().minimizeBox1();
          homepageAnimations.section10().onEnterBox2();
          homepageAnimations.section10().onLeaveBox3();
        } else if (nextIndex === 11) {
          homepageAnimations.section10().onContainerEnter();
          homepageAnimations.section10().onLeaveBox1();
          homepageAnimations.section10().minimizeBox2();
          homepageAnimations.section10().onEnterBox3();
          homepageAnimations.section11().onLeaveToBottom();
        } else if (nextIndex === 12) {
          homepageAnimations.section10().onLeaveToTop();
          homepageAnimations.section11().onEnter();
          homepageAnimations.section12().onLeaveToBottom();
        } else if (nextIndex === 13) {
          // homepageAnimations.section11().onMoveEnd();
          // homepageAnimations.section12().onLeaveToBottom();
          homepageAnimations.section11().onMoveToTop();
          homepageAnimations.section12().onEnter();
          if (!scrollingDown) {
            homepageAnimations.section12().onLeaveToBottom(true);
          }
        } else if (nextIndex === 14) {
          homepageAnimations.section11().onLeaveToTop();
          homepageAnimations.section12().onLeaveToTop();
        } else if (nextIndex === 15) {
          homepageAnimations.section12().moveFooterSection();
        }

        currentIndex.current = nextIndex;

        setTimeout(
          () => {
            animating.current = false;
          },
          scrollingDown ? ANIMATION_TIMEOUT : ANIMATION_TIMEOUT / SCROLLING_UP_DIVISOR,
        );
      };

      Observer.create({
        type: 'wheel,touch,pointer,resize',
        tolerance: 150,
        wheelSpeed: -1,
        target: window,

        onUp: () => {
          const mobileMenuEl = document.querySelector('.mobile-menu-open');
          if (!animating.current && mobileMenuEl == null) {
            gotoSection(currentIndex.current + 1);
            if (window.innerWidth < MEDIUM_VIEWPORT_MIN) {
              const headerContainer = document.querySelector('.header-container') as HTMLElement;
              if (headerContainer) {
                headerContainer.style.transform = 'translateY(-100%)';
              }
            }
          }
        },
        onDown: () => {
          const mobileMenuEl = document.querySelector('.mobile-menu-open');
          if (!animating.current && mobileMenuEl == null) {
            gotoSection(currentIndex.current - 1);
            const headerContainer = document.querySelector('.header-container') as HTMLElement;
            if (headerContainer) {
              headerContainer.style.transform = 'translateY(0)';
            }
          }
        },
      });
      goToInitialSection();
    },
    { scope: container },
  );

  return (
    <HomepageContainer ref={container}>
      <HomepageSection className="section-1" style={{ transform: 'translateY(0%)' }}>
        <HomepageSectionOuter>
          <HomepageSectionInner>
            <HomepageInitial />
          </HomepageSectionInner>
        </HomepageSectionOuter>
      </HomepageSection>
      <HomepageSection className="section-2">
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageInitialSecondContainer className={'initial-second-section'}>
              An integrated approach that yields innovative solutions
            </HomepageInitialSecondContainer>
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-3" style={{ minHeight: 'unset' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageServicesBackground />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-4" style={{ transform: 'translateY(0%)' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageServicesItem1 />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-5" style={{ transform: 'translateY(0%)' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageServicesItem2 />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-6" style={{ transform: 'translateY(0%)' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageServicesItem3 />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-7">
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageCapabilitiesFirst />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-8" style={{ position: 'relative', transform: 'translateY(200%)' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageCapabilitiesSecond />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-9" style={{ transform: 'translateY(150%)', maxWidth: '100vw' }}>
        <HomepageSectionInner style={{ alignItems: 'flex-start' }}>
          <HomepageSuccessStory />
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection className="section-10" style={{ maxWidth: '100vw' }}>
        <HomepageSectionInner>
          <HomepageSectionOuter>
            <HomepageAboutUs />
          </HomepageSectionOuter>
        </HomepageSectionInner>
      </HomepageSection>
      <HomepageSection
        className="section-11"
        style={{ opacity: 0, maxWidth: '100vw', height: 'auto', transform: 'translateY(150%)' }}
      >
        <HomepageSectionOuter>
          <HomepageSectionInner style={{ height: 'auto' }}>
            <HomepageWhatsUp />
          </HomepageSectionInner>
        </HomepageSectionOuter>
      </HomepageSection>
      <HomepageSection
        className="section-12"
        style={{ maxWidth: '100vw', minHeight: 'unset', transformOrigin: 'bottom' }}
      >
        <HomepageSectionOuter>
          <HomepageSectionInner style={{ alignItems: 'flex-end', minWidth: '100vw' }}>
            <HomepageStartANewProject />
          </HomepageSectionInner>
        </HomepageSectionOuter>
      </HomepageSection>
      <HomepageSection
        className="section-13"
        style={{
          maxWidth: '100vw',
          minHeight: 'unset',
          visibility: 'visible',
          transform: 'translateY(100%)',
          padding: 0,
        }}
      >
        <Footer footerData={props.footerData} style={{ position: 'absolute', top: 0 }} />
      </HomepageSection>
    </HomepageContainer>
  );
};

export default Homepage;
