import { gsap } from 'gsap';
import {
  LARGE_VIEWPORT_MIN,
  MEDIUM_VIEWPORT_MIN,
  X_LARGE_VIEWPORT_MIN,
} from '@/src/styles/responsive-breakpoints';

const MIN_MOBILE_HEIGHT = 700;
const MIN_MOBILE_WIDTH = 393;

const getMobileScaleVal = () => {
  const scaleValY = window.innerHeight / MIN_MOBILE_HEIGHT > 1 ? 1 : window.innerHeight / MIN_MOBILE_HEIGHT;
  const scaleValX = window.innerWidth / MIN_MOBILE_WIDTH > 1 ? 1 : window.innerWidth / MIN_MOBILE_WIDTH;
  return scaleValX > scaleValY ? scaleValY : scaleValX;
};

const getElementHeight = (className: string) => {
  const el = document.querySelector(className);
  return el?.clientHeight || 0;
};

export const homepageAnimations = {
  section1: () => {
    let sections = document.querySelectorAll('section');
    const section1 = sections[0];

    return {
      onEnter: () => {
        gsap.set(section1, { y: 0, opacity: 1, autoAlpha: 1, zIndex: 3 });
        gsap.set('.initial-image1', { x: '-45%', y: 0, scale: 1, opacity: 1 });
        gsap.set('.initial-image2', { x: '8%', y: 0, scale: 1, opacity: 1 });
        gsap.set('.initial-subtext', { y: 0, opacity: 1 });
        gsap.set('.initial-more-button', { y: 0, minHeight: '72px', opacity: 1 });
        gsap.set('.initial-arrow', { y: 0, opacity: 1 });
      },
      onLeaveToTop: () => {
        gsap.set(section1, { y: '-100%' });
        gsap.set('.initial-image1', { x: '-75%', y: '-20%', scale: 1.5, opacity: 0 });
        gsap.set('.initial-image2', { x: '38%', y: '-20%', scale: 1.5, opacity: 0 });
        gsap.set('.initial-subtext', { y: 100, opacity: 0 });
        gsap.set('.initial-more-button', { y: 0, minHeight: '20px', opacity: 0 });
        gsap.set('.initial-arrow', { y: 200, opacity: 0 });
      },
    };
  },
  section2: () => {
    let sections = document.querySelectorAll('section');
    const section2 = sections[1];

    return {
      onEnter: () => {
        gsap.set(section2, { y: 0, opacity: 1 });
      },
      onLeaveToTop: () => {
        gsap.set(section2, { y: '-100%', opacity: 0, transition: 1.5 });
      },
      onLeaveToBottom: () => {
        gsap.set(section2, { opacity: 0, y: '100%', transition: 1 });
      },
    };
  },
  section3: () => {
    const sections = document.querySelectorAll('section');
    const section3 = sections[2];
    const mobileAndTablet = window.innerWidth < LARGE_VIEWPORT_MIN;

    return {
      onEnter: () => {
        gsap.set(section3, { y: '50%', opacity: 1, transition: 1 });
        gsap.set('.services-circle-text', { rotate: 180 });
      },
      moveToTop: () => {
        gsap.set(section3, { y: '-50%', opacity: 1, transition: 1.5 });
        gsap.set('.services-circle-text', { rotate: '360deg', transition: 1.5 });
      },
      collapseContainer: () => {
        gsap.set('.services-container', { width: mobileAndTablet ? 210 : 300 });
        gsap.set('.services-circle', { width: mobileAndTablet ? 210 : 300 });
      },
      expandContainer: () => {
        gsap.set('.services-container', {
          width: window.innerWidth,
          height: `calc(150vh - ${mobileAndTablet ? 210 : 300}px)`,
        });
        gsap.set('.services-circle', { width: window.innerWidth });
      },
      onLeaveToTop: () => {
        gsap.set(section3, { opacity: 1, y: '-200%', transition: 1 });
      },
      onLeaveToBottom: () => {
        gsap.set(section3, { y: '100%', opacity: 0, transition: 1.5 });
        gsap.set('.services-circle-text', { rotate: '1deg', transition: 1.5 });
      },
    };
  },
  section4: () => {
    const mobileAndTablet = window.innerWidth < LARGE_VIEWPORT_MIN;

    return {
      onEnter: () => {
        if (mobileAndTablet) gsap.set('.services-item-text1', { y: 20, opacity: 1 });
        else gsap.set('.services-item-text1', { y: 0, opacity: 1 });
        gsap.set('.services-lines-svg', { y: 0, opacity: 1 });
        gsap.set('.services-earth-svg', { x: 0, y: 0, rotate: 0, opacity: 1 });
        gsap.set('.services-people-svg-2', { x: '10%', y: 0, rotate: 0, scale: 1, opacity: 1 });
      },
      onLeaveToBottom: () => {
        if (mobileAndTablet) gsap.set('.services-item-text1', { y: 0, opacity: 0 });
        else gsap.set('.services-item-text1', { y: '150%', opacity: 0 });
        gsap.set('.services-lines-svg', { opacity: 0 });
        gsap.set('.services-earth-svg', { x: '-35%', y: '5%', rotate: -15, opacity: 0 });
        gsap.set('.services-people-svg-2', { x: '30%', y: '5%', rotate: 15, opacity: 0 });
      },
      onLeaveToTop: () => {
        if (mobileAndTablet) {
          gsap.set('.services-item-text1', { y: '-150%', opacity: 0 });
          gsap.set('.services-lines-svg', { y: '-150%', opacity: 0 });
          gsap.set('.services-earth-svg', { y: '-150%', opacity: 0 });
        } else {
          gsap.set('.services-item-text1', { y: '-150%', opacity: 0 });
          gsap.set('.services-lines-svg', { opacity: 0 });
          gsap.set('.services-earth-svg', { opacity: 0 });
        }
      },
    };
  },
  section5: () => {
    const mobileAndTablet = window.innerWidth < LARGE_VIEWPORT_MIN;
    const servicesContainer2Height = getElementHeight('.services-svg-container2');
    const servicesText2Height = getElementHeight('.services-item-text2');
    const mobilePosition = (servicesContainer2Height + servicesText2Height + 160) / 2;

    return {
      onEnterFromTop: () => {
        if (mobileAndTablet) {
          gsap.set('.services-item-text2', { y: mobilePosition - 50, opacity: 1 });
          gsap.set('.services-item-text3', { y: 2 * window.innerHeight, opacity: 0 });
          gsap.set('.services-people-svg-2', {
            y: -1 * (mobilePosition + 50),
            x: '20%',
            rotate: 0,
            scale: 1.2,
            opacity: 1,
          });
          gsap.set('.services-dev-svg', { y: -1 * (mobilePosition + 50), left: '30%', opacity: 1 });
          gsap.set('.services-start-svg', { y: -1 * (mobilePosition + 50), left: 0, opacity: 1 });
          gsap.set('.services-post-note-svg', {
            y: -1 * (mobilePosition + 50),
            left: '85%',
            rotate: '15deg',
            opacity: 1,
          });
        } else {
          gsap.set('.services-item-text2', { y: 0, opacity: 1 });
          gsap.set('.services-people-svg-2', { y: 0, x: '20%', rotate: 0, scale: 1.2, opacity: 1 });
          gsap.set('.services-dev-svg', { y: 0, left: '30%', opacity: 1 });
          gsap.set('.services-start-svg', { y: 0, left: 0, opacity: 1 });
          gsap.set('.services-post-note-svg', { y: 0, left: '85%', rotate: '15deg', opacity: 1 });
        }
      },
      onEnterFromBottom: () => {
        if (mobileAndTablet) {
          gsap.set('.services-item-text2', { y: mobilePosition - 50, opacity: 1 });
          gsap.set('.services-people-svg-2', { y: -1 * (mobilePosition + 50), opacity: 1 });
          gsap.set('.services-dev-svg', { y: -1 * (mobilePosition + 50), left: '30%', opacity: 1 });
          gsap.set('.services-start-svg', { y: -1 * (mobilePosition + 50), left: 0, opacity: 1 });
          gsap.set('.services-post-note-svg', {
            y: -1 * (mobilePosition + 50),
            left: '85%',
            rotate: '15deg',
            opacity: 1,
          });
        } else {
          gsap.set('.services-item-text2', { y: 0, opacity: 1 });
          gsap.set('.services-people-svg-2', { opacity: 1 });
          gsap.set('.services-dev-svg', { left: '30%', opacity: 1 });
          gsap.set('.services-start-svg', { left: 0, opacity: 1 });
          gsap.set('.services-post-note-svg', { left: '85%', rotate: '15deg', opacity: 1 });
        }
      },
      onLeaveToTop: () => {
        if (mobileAndTablet) {
          gsap.set('.services-item-text2', { y: -1 * window.innerHeight, opacity: 0 });
          gsap.set('.services-people-svg-2', { y: -1.5 * window.innerHeight, opacity: 0 });
          gsap.set('.services-dev-svg', { y: -1.5 * window.innerHeight, left: '10%', opacity: 0 });
          gsap.set('.services-start-svg', { y: -1.5 * window.innerHeight, left: '-20%', opacity: 0 });
          gsap.set('.services-post-note-svg', {
            y: -1.5 * window.innerHeight,
            left: '100%',
            rotate: '30deg',
            opacity: 0,
          });
        } else {
          gsap.set('.services-item-text2', { y: '-150%', opacity: 0 });
          gsap.set('.services-people-svg-2', { opacity: 0 });
          gsap.set('.services-dev-svg', { left: '10%', opacity: 0 });
          gsap.set('.services-start-svg', { left: '-20%', opacity: 0 });
          gsap.set('.services-post-note-svg', { left: '100%', rotate: '30deg', opacity: 0 });
        }
      },
      onLeaveToBottom: () => {
        gsap.set('.services-item-text2', { y: 1.5 * window.innerHeight, opacity: 0 });
        gsap.set('.services-dev-svg', { left: '10%', opacity: 0 });
        gsap.set('.services-start-svg', { left: '-20%', opacity: 0 });
        gsap.set('.services-post-note-svg', { left: '100%', rotate: '30deg', opacity: 0 });
      },
    };
  },
  section6: () => {
    let sections = document.querySelectorAll('section');
    const section6 = sections[5];
    const mobileAndTablet = window.innerWidth < LARGE_VIEWPORT_MIN;
    const servicesContainer3Height = getElementHeight('.services-svg-container3');
    const servicesText3Height = getElementHeight('.services-item-text3');
    const mobilePosition = (servicesContainer3Height + servicesText3Height + 160) / 2;

    return {
      onEnter: () => {
        gsap.set(section6, { opacity: 1, y: 0 });
        if (mobileAndTablet) {
          gsap.set('.services-item-text3', { y: mobilePosition - 30, opacity: 1 });
          gsap.set('.services-circle-svg', { y: -1 * (mobilePosition + 40), rotate: 0, opacity: 1 });
          gsap.set('.services-ellipse-svg', { y: -1 * (mobilePosition + 40), opacity: 1 });
          gsap.set('.services-phone-svg', { y: -1 * (mobilePosition + 40), opacity: 1 });
          gsap.set('.services-rect-svg-1', {
            y: -1 * (mobilePosition + 40),
            left: '50%',
            top: '20%',
            opacity: 1,
          });
          gsap.set('.services-rect-svg-2', {
            y: -1 * (mobilePosition + 40),
            left: '60%',
            top: '-10%',
            opacity: 1,
          });
          gsap.set('.services-polygon-svg-1', {
            y: -1 * (mobilePosition + 40),
            left: '95%',
            top: '40%',
            opacity: 1,
          });
          gsap.set('.services-polygon-svg-2', {
            y: -1 * (mobilePosition + 40),
            left: '90%',
            top: '45%',
            opacity: 1,
          });
        } else {
          gsap.set('.services-item-text3', { y: 0, opacity: 1 });
          gsap.set('.services-circle-svg', { y: 0, rotate: 0, opacity: 1 });
          gsap.set('.services-ellipse-svg', { y: 0, opacity: 1 });
          gsap.set('.services-phone-svg', { y: 0, opacity: 1 });
          gsap.set('.services-rect-svg-1', { y: 0, left: '50%', top: '20%', opacity: 1 });
          gsap.set('.services-rect-svg-2', { y: 0, left: '60%', top: '-10%', opacity: 1 });
          gsap.set('.services-polygon-svg-1', { y: 0, left: '95%', top: '40%', opacity: 1 });
          gsap.set('.services-polygon-svg-2', { y: 0, left: '90%', top: '45%', opacity: 1 });
        }
      },
      onLeaveToBottom: () => {
        if (mobileAndTablet) {
          gsap.set('.services-item-text3', { y: 1.5 * window.innerHeight, opacity: 0 });
          gsap.set('.services-circle-svg', { y: window.innerHeight, rotate: '-150deg', opacity: 0 });
          gsap.set('.services-ellipse-svg', { y: window.innerHeight, opacity: 0 });
          gsap.set('.services-phone-svg', { y: window.innerHeight, opacity: 0 });
          gsap.set('.services-rect-svg-1', { y: window.innerHeight, left: '46%', top: '40%', opacity: 0 });
          gsap.set('.services-rect-svg-2', { y: window.innerHeight, left: '50%', top: '35%', opacity: 0 });
          gsap.set('.services-polygon-svg-1', { y: window.innerHeight, left: '65%', top: '70%', opacity: 0 });
          gsap.set('.services-polygon-svg-2', { y: window.innerHeight, left: '60%', top: '75%', opacity: 0 });
        } else {
          gsap.set('.services-item-text3', { y: '150%', opacity: 0 });
          gsap.set('.services-circle-svg', { rotate: '-150deg', opacity: 0 });
          gsap.set('.services-ellipse-svg', { opacity: 0 });
          gsap.set('.services-phone-svg', { opacity: 0 });
          gsap.set('.services-rect-svg-1', { left: '46%', top: '40%', opacity: 0 });
          gsap.set('.services-rect-svg-2', { left: '50%', top: '35%', opacity: 0 });
          gsap.set('.services-polygon-svg-1', { left: '65%', top: '70%', opacity: 0 });
          gsap.set('.services-polygon-svg-2', { left: '60%', top: '75%', opacity: 0 });
        }
      },
      onLeaveToTop: () => {
        gsap.set(section6, { y: '-150%' });
        gsap.set('.services-circle-svg', { opacity: 1, rotate: '-150deg' });
        gsap.set('.services-ellipse-svg', { opacity: 1 });
        gsap.set('.services-phone-svg', { opacity: 1 });
        gsap.set('.services-rect-svg-1', { opacity: 1, left: '46%', top: '40%' });
        gsap.set('.services-rect-svg-2', { opacity: 1, left: '50%', top: '35%' });
        gsap.set('.services-polygon-svg-1', { opacity: 1, left: '65%', top: '70%' });
        gsap.set('.services-polygon-svg-2', { opacity: 1, left: '60%', top: '75%' });
      },
    };
  },
  section7: () => {
    let sections = document.querySelectorAll('section');
    const section7 = sections[6];

    return {
      onEnter: () => {
        gsap.set(section7, { opacity: 1, y: 0 });
        gsap.set('.capabilities-circle', { opacity: 1, y: 0 });
        gsap.set('.capabilities-jump', { opacity: 1, y: 0, x: 0 });
        gsap.set('.capabilities-text', { opacity: 1, y: 0 });
      },
      onLeaveToTop: () => {
        gsap.set(section7, { y: '-100%', opacity: 0.2 });
        gsap.set('.capabilities-circle', { opacity: 0.2, y: '-100%' });
        gsap.set('.capabilities-jump', { opacity: 0.2, y: '-100%', x: '50%' });
        gsap.set('.capabilities-text', { opacity: 0.2, y: '100%' });
      },
      onLeaveToBottom: () => {
        gsap.set(section7, { opacity: 0, y: '100%' });
        gsap.set('.capabilities-circle', { opacity: 0.3, y: 0 });
        gsap.set('.capabilities-jump', { opacity: 0.3, y: 0, x: 0 });
        gsap.set('.capabilities-text', { opacity: 0.3, y: 0 });
      },
    };
  },
  section8: () => {
    let sections = document.querySelectorAll('section');
    const section8 = sections[7];
    const secondaryContainerHeight = getElementHeight('.capabilities-secondary-container');
    const scaleVal = getMobileScaleVal();
    const percent = -1 * (scaleVal * (secondaryContainerHeight + 120) - window.innerHeight);

    return {
      onEnter: () => {
        gsap.set(section8, { opacity: 1, y: 0 });
      },
      scrollMobile: () => {
        gsap.set(section8, { opacity: 1, y: percent });
      },
      onLeaveToTop: () => {
        gsap.set(section8, { opacity: 0, y: '-200%' });
      },
      onLeaveToBottom: () => {
        gsap.set(section8, { opacity: 0, y: '100%' });
      },
    };
  },
  section9: () => {
    let sections = document.querySelectorAll('section');
    const section9 = sections[8];
    const mobileAndTablet = window.innerWidth < LARGE_VIEWPORT_MIN;

    return {
      onEnter: () => {
        gsap.set(section9, { y: 0, transition: 0.7 });
      },
      onCollapse: () => {
        if (!mobileAndTablet) {
          if (window.innerWidth > X_LARGE_VIEWPORT_MIN) {
            const startX = (window.innerWidth - 1200) / 2;
            const startY = 168;
            const endX = startX + 1200;
            const endY = startY + (window.innerHeight - 336);
            gsap.set('.success-story-background', {
              clip: `rect(${startY}px, ${endX}px, ${endY}px, ${startX}px)`,
            });
            gsap.set('.success-story-container', { width: '948px' });
            gsap.set('.success-story-phones', {
              marginRight: '0',
              height: window.innerHeight - 462 > 560 ? 560 : window.innerHeight - 462,
            });
          } else {
            const startX = (window.innerWidth - 948) / 2;
            const startY = 168;
            const endX = startX + 948;
            const endY = startY + (window.innerHeight - 336);
            gsap.set('.success-story-background', {
              clip: `rect(${startY}px, ${endX}px, ${endY}px, ${startX}px)`,
            });
            gsap.set('.success-story-container', { width: '800px' });
            gsap.set('.success-story-phones', {
              opacity: 0,
              marginRight: '-80px',
              height: 200,
            });
          }
        } else {
          gsap.set('.success-story-phones', { height: 'auto' });
        }
      },
      onExpand: () => {
        gsap.set('.success-story-background', { clip: 'rect(0, 100vw, 100vh, 0)' });

        if (mobileAndTablet) {
          gsap.set('.success-story-container', { width: '100%' });
        } else {
          gsap.set('.success-story-container', {
            width: window.innerWidth > X_LARGE_VIEWPORT_MIN ? '1200px' : '948px',
          });
          gsap.set('.success-story-phones', {
            opacity: 1,
            marginRight: window.innerWidth > X_LARGE_VIEWPORT_MIN ? '104px' : '0',
            height: window.innerHeight - 336 > 560 ? 560 : window.innerHeight - 336,
          });
        }
      },
      onLeaveToTop: () => {
        gsap.set(section9, { y: '-150%', transition: 1 });
      },
      onLeaveToBottom: () => {
        gsap.set(section9, { y: '150%', transition: 1 });
      },
    };
  },
  section10: () => {
    let sections = document.querySelectorAll('section');
    const section10 = sections[9];

    return {
      minimizeBox1: () => {
        gsap.set('.about-us-box-1', { y: -51, scale: 0.8, opacity: 0.7 });
      },
      onLeaveBox1: () => {
        gsap.set('.about-us-box-1', { scale: 0.5, opacity: 0 });
      },
      onEnterBox2: () => {
        gsap.set('.about-us-box-2', { y: 0, scale: 1, opacity: 1 });
      },
      minimizeBox2: () => {
        gsap.set('.about-us-box-2', { y: -51, scale: 0.8, opacity: 0.7 });
      },
      onLeaveBox2: () => {
        gsap.set('.about-us-box-2', { y: '100%', opacity: 0 });
      },
      onEnterBox3: () => {
        gsap.set('.about-us-box-3', { y: 0, opacity: 1 });
      },
      onLeaveBox3: () => {
        gsap.set('.about-us-box-3', { y: '100%', opacity: 0 });
      },
      onEnter: () => {
        gsap.set(section10, { y: 0, opacity: 1 });
        gsap.set('.about-us-text', { scale: 1, opacity: 1 });
        gsap.set('.about-us-box-1', { y: 0, scale: 1, opacity: 1 });
      },
      onContainerEnter: () => {
        gsap.set(section10, { y: 0, opacity: 1 });
      },
      onLeaveToTop: () => {
        gsap.set(section10, { y: '-150%' });
      },
      onLeaveToBottom: () => {
        gsap.set(section10, { y: '150%', opacity: 0 });
        gsap.set('.about-us-text', { scale: 2, opacity: 0 });
        gsap.set('.about-us-box-1', { y: '100%', opacity: 0 });
      },
    };
  },
  section11: () => {
    const sections = document.querySelectorAll('section');
    const section11 = sections[10];
    const startANewProjectHeight = getElementHeight('.start-a-new-project');
    const isMobile = window.innerWidth < MEDIUM_VIEWPORT_MIN;
    const scaleVal = getMobileScaleVal();

    return {
      onMoveToTop: () => {
        if (section11.offsetHeight > window.innerHeight) {
          if (scaleVal < 1) {
            const valY =
              startANewProjectHeight * scaleVal +
              (section11.offsetHeight * scaleVal - window.innerHeight) +
              scaleVal * (isMobile ? 80 : 176);
            gsap.set(section11, {
              y: -1 * valY,
              zIndex: 3,
            });
          } else {
            gsap.set(section11, {
              y:
                -1 *
                (startANewProjectHeight +
                  (section11.offsetHeight - window.innerHeight) +
                  (isMobile ? 80 : 176)),
              zIndex: 3,
            });
          }
        } else {
          gsap.set(section11, {
            y:
              -1 *
              (startANewProjectHeight -
                (window.innerHeight - section11.offsetHeight) +
                (isMobile ? 80 : 176)),
            zIndex: 3,
          });
        }
      },
      onMoveEnd: () => {
        if (section11.offsetHeight > window.innerHeight) {
          if (scaleVal < 1) {
            const valY =
              section11.offsetHeight * scaleVal - window.innerHeight + scaleVal * (isMobile ? 40 : 88);
            const extraHeight =
              (section11.offsetHeight * scaleVal) / window.innerHeight > 1.5
                ? 0.5 * (section11.offsetHeight * scaleVal - window.innerHeight)
                : 0;
            gsap.set(section11, { y: -1 * (valY - extraHeight), zIndex: 3 });
          } else {
            const valY = section11.offsetHeight - window.innerHeight + (isMobile ? 40 : 88);
            const extraHeight =
              section11.offsetHeight / window.innerHeight > 1.5
                ? 0.5 * (section11.offsetHeight - window.innerHeight)
                : 0;
            gsap.set(section11, { y: -1 * (valY - extraHeight), zIndex: 3 });
          }
        } else {
          const valY = window.innerHeight - section11.offsetHeight + (isMobile ? 40 : 88);
          gsap.set(section11, { y: -1 * valY, zIndex: 3 });
        }
      },
      onEnter: () => {
        gsap.set(section11, { y: 0, opacity: 1, zIndex: 3 });
      },
      onLeaveToTop: () => {
        gsap.set(section11, { y: '-150%', zIndex: 0 });
      },
      onLeaveToBottom: () => {
        gsap.set(section11, { y: '150%', zIndex: 0 });
      },
    };
  },
  section12: () => {
    const sections = document.querySelectorAll('section');
    const section12 = sections[11];
    const section13 = sections[12];
    const scaleVal = getMobileScaleVal();
    const footerHeight = scaleVal * getElementHeight('.footer-section');

    return {
      onEnter: () => {
        gsap.set(section12, { y: 0 });
      },
      onLeaveToTop: () => {
        if (window.innerHeight < footerHeight) {
          gsap.set(section13, { y: 0 });
          gsap.set(section12, { y: '-100%' });
        } else {
          gsap.set(section13, { y: window.innerHeight - footerHeight });
          gsap.set(section12, { y: -1 * footerHeight });
        }
      },
      onLeaveToBottom: (displayOverlapBanner = false) => {
        gsap.set(section13, { y: '100%' });
        gsap.set(section12, { y: displayOverlapBanner ? 0 : '100%' });
      },
      moveFooterSection: () => {
        if (window.innerHeight < footerHeight) {
          gsap.set(section13, { y: window.innerHeight - footerHeight });
        }
      },
    };
  },
};
