import React from 'react';
import CapabilitiesCircle from '@/src/assets/png/capabilities-circle.png';
import CapabilitiesJump from '@/src/assets/png/capabilities-jump.png';
import {
  HomepageCapabilitiesCircleSvg,
  HomepageCapabilitiesContainer,
  HomepageCapabilitiesHeader,
  HomepageCapabilitiesHeaderInner,
  HomepageCapabilitiesItemDivider,
  HomepageCapabilitiesItems,
  HomepageCapabilitiesItemText,
  HomepageCapabilitiesJumpSvg,
  HomepageCapabilitiesSecondaryContainer,
  HomepageCapabilitiesSecondaryContainerInner,
  HomepageCapabilitiesSecondaryHeader,
  HomepageCapabilitiesSvgContainer,
} from '@/src/styles/homepage-styles';
import { LargeImageQuery } from '@/src/styles/responsive-breakpoints';

export const HomepageCapabilitiesFirst = () => {
  return (
    <HomepageCapabilitiesContainer>
      <HomepageCapabilitiesHeader className={'capabilities-text'}>
        Our client engagements revolve around
        <HomepageCapabilitiesHeaderInner> innovation and modernization.</HomepageCapabilitiesHeaderInner>
      </HomepageCapabilitiesHeader>
      <HomepageCapabilitiesSvgContainer>
        <HomepageCapabilitiesCircleSvg
          className={'capabilities-circle'}
          src={CapabilitiesCircle}
          alt={'capabilities-circle'}
          sizes={`${LargeImageQuery} 282px, 100vw`}
        />
        <HomepageCapabilitiesJumpSvg
          className={'capabilities-jump'}
          src={CapabilitiesJump}
          alt={'capabilities-jump'}
          sizes={`${LargeImageQuery} 234px, 100vw`}
        />
      </HomepageCapabilitiesSvgContainer>
    </HomepageCapabilitiesContainer>
  );
};

export const HomepageCapabilitiesSecond = () => {
  return (
    <HomepageCapabilitiesSecondaryContainer>
      <HomepageCapabilitiesSecondaryContainerInner className={'capabilities-secondary-container'}>
        <HomepageCapabilitiesSecondaryHeader className={'h6'}>
          Our Core Capabilities
        </HomepageCapabilitiesSecondaryHeader>
        <HomepageCapabilitiesItems>
          <HomepageCapabilitiesItemDivider />
          {[
            'Digital Footprint Assessment & Roadmap',
            'Product & Customer Experience Strategy',
            'User Experience Research & Design',
            'Backend Systems Development & Integration',
            'Web & Mobile Applications Development',
            'AI + Machine Learning',
            'Re-architecture of Software Applications',
            'Dev SecOps Pipeline & \n Developer Experience Best Practice Consulting',
          ].map((text, index) => (
            <React.Fragment key={index}>
              <HomepageCapabilitiesItemText>{text}</HomepageCapabilitiesItemText>
              <HomepageCapabilitiesItemDivider />
            </React.Fragment>
          ))}
        </HomepageCapabilitiesItems>
      </HomepageCapabilitiesSecondaryContainerInner>
    </HomepageCapabilitiesSecondaryContainer>
  );
};
