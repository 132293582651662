import React from 'react';
import SuccessStoryBg from '@/src/assets/jpeg/success-story-bg.jpeg';
import SuccessStoryPhones from '@/src/assets/svg/success-story-phones.svg';
import Button from '@/src/components/ui/Button';
import {
  HomepageSuccessStoryChip,
  HomepageSuccessStoryContainer,
  HomepageSuccessStoryImage,
  HomepageSuccessStoryImageContainer,
  HomepageSuccessStoryImageShadow,
  HomepageSuccessStoryInnerContainer,
  HomepageSuccessStoryInnerLeft,
  HomepageSuccessStoryInnerRight,
  HomepageSuccessStoryPhones,
  HomepageSuccessStoryText,
} from '@/src/styles/homepage-styles';
import { CONTACT_US_SLUG } from '@/src/constants/route-constants';
import { FaArrowRight } from 'react-icons/fa6';

const HomepageSuccessStory = () => {
  return (
    <HomepageSuccessStoryContainer className={'success-story'}>
      <HomepageSuccessStoryImageContainer>
        <HomepageSuccessStoryImage
          className={'success-story-background'}
          src={SuccessStoryBg}
          alt={'success-story-background'}
          sizes={'100vw'}
        />
        <HomepageSuccessStoryImageShadow />
      </HomepageSuccessStoryImageContainer>
      <HomepageSuccessStoryInnerContainer className={'success-story-container'}>
        <HomepageSuccessStoryInnerLeft className={'success-story-container-left'}>
          <HomepageSuccessStoryChip>Success Story</HomepageSuccessStoryChip>
          <HomepageSuccessStoryText>
            Modernizing a Legacy System for a Healthcare Franchise
          </HomepageSuccessStoryText>
          <Button $buttonType={'primary'} style={{ marginTop: 16 }} href={`/${CONTACT_US_SLUG}`}>
            Learn More
            <FaArrowRight size={20} />
          </Button>
        </HomepageSuccessStoryInnerLeft>
        <HomepageSuccessStoryInnerRight>
          <HomepageSuccessStoryPhones
            className={'success-story-phones'}
            src={SuccessStoryPhones}
            alt={'success-story-phones'}
          />
        </HomepageSuccessStoryInnerRight>
      </HomepageSuccessStoryInnerContainer>
    </HomepageSuccessStoryContainer>
  );
};

export default HomepageSuccessStory;
